<template>
  <Main>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <sdCards headless>
          <a-form
            ref="formRef"
            name="sDash_validation-form"
            layout="vertical"
            :model="formState"
            :validateOnRuleChange="false"
          >
            <a-row :gutter="[32,0]">
              <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <h2>{{$t('views.registration.companyDetails')}}</h2>
                <a-row :gutter="[32,0]">
                  <template
                    v-for="field in visibleFields"
                    :key="field"
                  >
                    <form-fields
                      :field="fields[field]"
                      :name="field"
                      :fieldValue ="formState[field]"
                      :validateAgainst="formState[fields[field].validateAgainst] || undefined"
                      @input-changed="inputChanged($event, field)"
                      @validateField="validateField"
                    />
                  </template>
                </a-row>
              </a-col>
              <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <h2>{{$t('views.registration.address')}}</h2>
                <a-row :gutter="[32,0]">
                  <template
                    v-for="field in ['country', 'city', 'street']"
                    :key="field"
                  >
                    <form-fields
                      :field="fields[field]"
                      :name="field"
                      :fieldValue ="formState[field]"
                      @input-changed="inputChanged($event, field)"
                    />
                  </template>
                </a-row>

                <h2>{{$t('views.registration.uploadDocuments')}}</h2>
                <a-row :gutter="[32,0]">
                  <template
                    v-for="field in ['businessRegistrationCertificate', 'tradeLicense']"
                    :key="field"
                  >
                    <form-fields
                      :field="fields[field]"
                      :name="field"
                      :fieldValue ="formState[field]"
                      @input-changed="inputChanged($event, field)"
                    />
                  </template>
                </a-row>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
        <div class="sDash_form-action">
          <a-button
            class="btn-signin mr-10 float-button-right"
            size="small"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="submitForm"
          >
            <span>{{companyEditMode ?  $t('actions.saveAndNext') : $t('actions.next')}}</span>
          </a-button>
        </div>
      </VerticalFormStyleWrap>
    </FormValidationWrap>
  </Main>
</template>

<script>
import {
  computed, defineComponent, reactive, ref
} from 'vue';
import {useI18n} from 'vue-i18n';
import {
  required, email, phoneNumber
} from '@/helpers/validationRules';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import {FieldStyles} from '@/components/shared/fields/styles';
import FormFields from '@/components/shared/form/FormFields';
import _ from 'lodash';
import {shipmentRequestTypes} from '@/constants';
import {Main} from '@/components/shared/styledComponents/Main';
import {getCitiesOfACountry} from '@/helpers/citiesOptions';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'ClientCompanyDetails',
  emits: ['submit:companyDetails'],
  components: {
    VerticalFormStyleWrap,
    FormValidationWrap,
    FormFields,
    Main
  },
  props: {
    data: VueTypes.object,
    loading: VueTypes.bool.def(false),
    showIorEorField: VueTypes.bool.def(false),
    editMode: VueTypes.bool.def(false),
    companyEditMode: VueTypes.bool.def(false),
    currentPassword: VueTypes.string.def('')
  },
  setup(props, context) {
    const {t} = useI18n();
    const {getters} = useStore();

    const formRef = ref();
    const formState = props.data ? reactive(props.data) : reactive({});

    const fields = reactive({
      name: {
        type: 'text',
        fieldValue: props.data['name'] || '',
        label: 'models.company.attributes.name',
        placeholder: 'models.company.attributes.name',
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20})
      },
      registrationNumber: {
        type: 'text',
        fieldValue: props.data['registrationNumber'] || '',
        label: 'models.company.attributes.registrationNumber',
        placeholder: 'models.company.attributes.registrationNumber',
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20})
      },
      email: {
        type: 'text',
        fieldValue: props.data['email'] || '',
        label: 'models.user.attributes.email',
        placeholder: 'models.user.attributes.email',
        rules: [required, email],
        size: 'large',
        styles: new FieldStyles({md: 20})
      },
      firstName: {
        type: 'text',
        fieldValue: props.data['firstName'] || '',
        label: 'models.user.attributes.firstName',
        rules: [required],
        placeholder: 'models.user.attributes.firstName',
        size: 'large',
        styles: new FieldStyles({md: 10})
      },
      lastName: {
        type: 'text',
        fieldValue: props.data['lastName'] || '',
        label: 'models.user.attributes.lastName',
        rules: [required],
        placeholder: 'models.user.attributes.lastName',
        size: 'large',
        styles: new FieldStyles({md: 10})
      },
      password: {
        type: 'password',
        fieldValue: props.data['password'] || '',
        validateDependent: 'passwordConfirmation',
        rules: [
          {...required, message: t('messages.error.required')},
          {
            min: 8, message: t('messages.error.minLength', {min: 8}), trigger: ['blur', 'change'], type: 'string'
          }],
        label: 'models.user.attributes.password',
        placeholder: 'models.user.attributes.password',
        size: 'large',
        styles: new FieldStyles({xl: 10, md: 20})
      },
      passwordConfirmation: {
        type: 'password',
        fieldValue: props.data['passwordConfirmation'] || '',
        validateAgainst: 'password',
        customValidation: true,
        rules: [required],
        label: 'models.user.attributes.passwordConfirmation',
        placeholder: 'models.user.attributes.passwordConfirmation',
        size: 'large',
        styles: new FieldStyles({xl: 10, md: 20})
      },
      phoneCode: {
        type: 'select',
        fieldValue: props.data['phoneCode'] || '',
        label: 'models.company.attributes.phone',
        placeholder: 'models.company.attributes.code',
        size: 'large',
        rules: [required],
        options: computed(() => {
          return _.map(getters.countries, (country) => {
            return {
              label: country.phoneCode, value: country.phoneCode, code: country.value
            };
          });
        }),
        showCountryFlags: true,
        enableSearch: true,
        styles: new FieldStyles({md: 8})
      },
      phone: {
        type: 'number',
        allowDecimal: false,
        fieldValue: props.data['phone'] || '',
        placeholder: 'models.company.attributes.phone',
        size: 'large',
        rules: [phoneNumber(1, 12)],
        styles: new FieldStyles({md: 12})
      },
      contactPersonName: {
        type: 'text',
        fieldValue: props.data['contactPersonName'] || '',
        label: 'models.company.attributes.contactPersonName',
        placeholder: 'models.company.attributes.contactPersonName',
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20})
      },
      contactPersonPosition: {
        type: 'text',
        fieldValue: props.data['contactPersonPosition'] || '',
        label: 'models.company.attributes.contactPersonPosition',
        placeholder: 'models.company.attributes.contactPersonPosition',
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20})
      },
      country: {
        type: 'select',
        fieldValue: props.data['country'] || '',
        label: 'models.company.attributes.country',
        placeholder: 'models.company.attributes.country',
        options: computed(() => getters.countries),
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20}),
        enableSearch: true
      },
      serviceTypes: {
        type: 'multipleSelect',
        label: 'models.company.attributes.serviceTypes',
        placeholder: 'models.company.attributes.serviceTypes',
        fieldValue: props.data['serviceTypes'] || undefined,
        styles: new FieldStyles({md: 20}),
        rules: computed(() => props.showIorEorField ? [{...required, type: 'array'}] : []),
        options: [
          {
            value: shipmentRequestTypes.IOR, label: t('models.shipmentRequest.attributes.shipmentTypes.IOR')
          },
          {
            value: shipmentRequestTypes.EOR, label: t('models.shipmentRequest.attributes.shipmentTypes.EOR')
          }
        ]
      },
      productCategoryIds: {
        type: 'multipleSelect',
        label: 'models.company.attributes.productCategories',
        placeholder: 'models.company.attributes.productCategories',
        fieldValue: props.data['productCategoryIds'] || undefined,
        styles: new FieldStyles({md: 20}),
        rules: computed(() => props.showIorEorField ? [{...required, type: 'array'}] : []),
        options: computed(() => {
          return _.filter(getters.productCategories, (option) => {
            return option.active || _.includes(props.data['productCategoryIds'], option.value);
          });
        })
      },
      city: {
        type: 'select',
        fieldValue: props.data['city'] || '',
        label: 'models.company.attributes.city',
        placeholder: 'models.company.attributes.city',
        options: computed(() => getCitiesOfACountry(formState?.country)),
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20}),
        enableSearch: true,
        disabled: computed(() => _.isEmpty(formState?.country))
      },
      street: {
        type: 'text',
        fieldValue: props.data['street'] || '',
        label: 'models.company.attributes.street',
        placeholder: 'models.company.attributes.street',
        rules: [required],
        size: 'large',
        styles: new FieldStyles({md: 20})
      },
      tradeLicense: {
        type: 'file',
        label: 'models.company.attributes.tradeLicense',
        placeholder: 'actions.uploadTradeLicence',
        fieldValue: props.data['tradeLicense'] || [],
        styles: new FieldStyles({md: 20}),
        required: true,
        multiple: false
      },
      businessRegistrationCertificate: {
        type: 'file',
        label: 'models.company.attributes.businessRegistrationCertificate',
        placeholder: 'actions.uploadBusinessRegistrationCertificate',
        fieldValue: props.data['businessRegistrationCertificate'] || [],
        styles: new FieldStyles({md: 20}),
        required: true,
        multiple: false
      }
    });

    const inputChanged = (value, key) => {
      if (key === 'email') value = value.replace(/\s/g, '');
      if (key === 'country') formState['city'] = '';
      formState[key] = value;
    };

    const submitForm = (e) => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(() => context.emit('submit:companyDetails', formState))
        .catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    const validateField = (key) => {
      formRef.value.validateField(key);
    };

    const visibleFields = computed(() => {
      const fields = ['name', 'phoneCode', 'phone',
        'contactPersonName', 'contactPersonPosition', 'registrationNumber'];
      if (props.showIorEorField) fields.push(...['serviceTypes', 'productCategoryIds']);
      if (!props.companyEditMode) fields.splice(0, 0, ...['firstName', 'lastName', 'email']);
      if ((props.currentPassword || !props.editMode) && !props.companyEditMode) fields.splice(3, 0, ...['password', 'passwordConfirmation']);
      return fields;
    });

    return {
      formRef,
      fields,
      formState,
      inputChanged,
      submitForm,
      validateField,
      visibleFields
    };
  }
});
</script>
