<template>
  <Main>
    <BasicFormWrapper>
      <sdCards headless>
        <a-row justify="center">
          <a-col :xs="24">
            <div class="atbd-finish-order" :style="{ width: '100%' }">
              <sdHeading as="h4">{{$t('views.registration.termsAndConditions')}}</sdHeading>
              <a-checkbox v-model:checked="checked">
                <span class="checkbox-label">
                  {{$t('views.registration.termsAndConditionsAgree')}}
                </span>
              </a-checkbox>
            </div>
          </a-col>
        </a-row>
      </sdCards>
    </BasicFormWrapper>
    <div class="sDash_form-action">
      <a-button
        class="btn-signin mr-10"
        size="small"
        type="default"
        @click="$emit('previousStep')">
        <span>{{ $t('actions.previous') }}</span>
      </a-button>
      <span class="float-button-right">
        <a-button
          class="btn-signin mr-10"
          size="small"
          :disabled="!checked || loading"
          type="primary"
          :loading="loading"
          @click="$emit('finishRegistration')"
        >
          <span>{{ $t('actions.save') }}</span>
        </a-button>
      </span>
    </div>
  </Main>
</template>

<script>
import {
  defineComponent, ref, watch
} from 'vue';
import VueTypes from 'vue-types';
import {BasicFormWrapper} from '@/components/shared/styledComponents/Main';
import {Main} from '@/components/shared/styledComponents/Main';

export default defineComponent({
  name: 'TermsAndConditions',
  emits: ['previousStep', 'finishRegistration'],
  components: {
    BasicFormWrapper,
    Main
  },
  props: {
    loading: VueTypes.bool.def(false),
    value: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const checked = ref(false);
    watch(() => props.value, (value) => checked.value = value, {immediate: true});
    return {
      checked
    };
  }
});
</script>

<style lang="scss" scoped>
.sDash_form-action button {
  border-radius: 4px;
  padding: 6.4px 29.2px;
  height: 44px;
}

@media only screen and (max-width: 500px){
  .sDash_form-action button,
  .sDash_form-action span {
    width: 100% !important;
  }
}
</style>
